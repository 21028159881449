import {
    apiList,
    Api
} from "@/assets/js/api";
import tools from "@/assets/js/tool";
import draggable from "vuedraggable"; //拖拽组件

import sorttable from "@/components/sortTemplate.vue";
import paging from "@/components/pagings.vue";
import multitime from "@/components/multiTime.vue";

export default {
    inject: ["reload"],
    components: {
        draggable,
        paging,
        sorttable,
        multitime
    },
    data() {
        return {
            activeName: 'first',
            isActive: true, //  输入框绑定动态class
            judge: false, //  输入框绑定动态class
            MoreConditions: '更多条件', //  输入框绑定动态class
            number1: "",
            queryData: {},
            pageNum: 1, //  页码
            total: 0,
            sizeList: [10, 20, 50, 100, 200],
            size: 50, //  一页几条
            tableHeigth: "0",
            singleData: [], //  表格选择的数据
            channelAndTransportList: [], //  渠道&运输方式下拉数据
            transportList: [], //  运输方式下拉菜单
            channelList: [], //  渠道方式下拉菜单
            agentIdArr: [], //  渠道代理
            storageArr: [], //  仓库下拉

            pageStart: 1,
            pageCount: 0,
            pageTotal: 50,
            time_first: [],
            originalTabelHeadeTitle1: [],
            dialogFormVisible: false,
            input: '',
            dialogImageUrl: '',
            dialogVisible: false,
            CancelSignIn: false,
            viewPicture: false,

            form: {
                name: '',
            },
            WarehouseList: [], //仓库数组
            inspectionStatusList: [], //验货状态列表
            rejectionStatusList: [], //拒收单拒收状态
            packageStatusList: [], ////包裹状态


            options: [{
                name: "a",
                val: 1,
            },
            {
                name: "b",
                val: 2,
            },
            ],
            // ======公用组件==========

            nextPage: false, //是否有下一页
            show_sortTableHeard: false, //显示弹窗
            show_sortTableHeard1: false, //显示弹窗
            show_upload: false, //显示上传弹窗
            myTableHeard: [], //我的表头
            originalTabelHeadeTitle: [],
            myTableHeard1: [], //我的表头
            selectLength: 0, //显示的长度
            selectLength1: 0, //显示的长度
            allCheck: false, //全选
            allCheck1: false, //全选
            tabelHeadeTitle: [{
                name: "打包批次号",
                field: "billPackNumber",
                width: "180",
                sort: 16,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "出库单号",
                field: "storepackNumber",
                width: "180",
                sort: 16,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
              name: "提货方式",
              field: "handoverModeShow",
              width: "120",
              sort: 1,
              isShow: true,
              isTotal: false,
              isSort: true,
            },
            {
              name: "商店代号",
              field: "storeIdNumber",
              width: "120",
              sort: 1,
              isShow: true,
              isTotal: false,
              isSort: true,
            },
            {
              name: "分店名称",
              field: "storeName",
              width: "120",
              sort: 1,
              isShow: true,
              isTotal: false,
              isSort: true,
            },
            {
              name: "门店地址",
              field: "storeAddress",
              width: "120",
              sort: 1,
              isShow: true,
              isTotal: false,
              isSort: true,
            },
            {
                name: "仓库",
                field: "storageName",
                width: "80",
                sort: 2,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "会员名称",
                field: "nickname",
                width: "120",
                sort: 3,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "会员号",
                field: "memberId",
                width: "80",
                sort: 4,
                isShow: true,
                isTotal: false,
                isSort: true,
            },

            {
                name: "业务单据类型",
                field: "billPackTypeShow",
                width: "120",
                sort: 1,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "是否暂扣",
                field: "withholdStatusShow",
                width: "120",
                sort: 1,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "打包状态",
                field: "storepackStatusShow",
                width: "120",
                sort: 1,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "付款方式",
                field: "paymentMethodStatusShow",
                width: "120",
                sort: 1,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "收件人姓名",
                field: "consigneeName",
                width: "120",
                sort: 1,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "收件人电话",
                field: "receivingPhone",
                width: "120",
                sort: 1,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "收货国家/地区",
                field: "chineseName",
                width: "120",
                sort: 1,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "收货邮政编号",
                field: "postCode",
                width: "120",
                sort: 1,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "收货城市",
                field: "cityName",
                width: "120",
                sort: 1,
                isShow: true,
                isTotal: false,
                isSort: true,
            },


            {
                name: "收货详细地址",
                field: "addressee",
                width: "180",
                sort: 1,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "团号",
                field: "invitationCode",
                width: "120",
                sort: 1,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "运输方式",
                field: "transportName",
                width: "120",
                sort: 1,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "渠道名称",
                field: "channelName",
                width: "120",
                sort: 1,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "渠道代理",
                field: "agentName",
                width: "120",
                sort: 1,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "运单号",
                field: "channelNumber",
                width: "120",
                sort: 1,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "包裹重量(KG)",
                field: "weight",
                width: "80",
                sort: 13,
                isShow: true,
                isTotal: true,
                isSort: true,
            },
            {
                name: "包裹体积(m³)",
                field: "volume",
                width: "80",
                sort: 14,
                isShow: true,
                isTotal: true,
                isSort: true,
            },
            {
                name: "包裹件数",
                field: "count",
                width: "80",
                sort: 12,
                isShow: true,
                isTotal: true,
                isSort: true,
            },

            {
                name: "包裹备注",
                field: "comment",
                width: "80",
                sort: 15,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "打包金额",
                field: "settlementAmount",
                width: "120",
                sort: 1,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "打包申请时间",
                field: "packApplyTime",
                width: "180",
                sort: 22,
                isShow: true,
                isTotal: false,
                isSort: true,
            },


            ],
            tabelHeadeTitle1: [{
                name: "运单号",
                field: "channelNumber",
                width: "180",
                sort: 5,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            // {
            //     name: "快递单号(无)",
            //     field: "",
            //     width: "180",
            //     sort: 5,
            //     isShow: true,
            //     isTotal: false,
            //     isSort: true,
            // },
            {
                name: "快递公司",
                field: "expressName",
                width: "120",
                sort: 1,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "打包批次号",
                field: "billPackNumber",
                width: "180",
                sort: 16,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "仓库",
                field: "storageName",
                width: "80",
                sort: 2,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "会员名称",
                field: "nickname",
                width: "120",
                sort: 3,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "会员号",
                field: "memberId",
                width: "80",
                sort: 4,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "团号",
                field: "invitationCode",
                width: "120",
                sort: 1,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            /* {
                name: "国际快递单号",
                field: "",
                width: "120",
                sort: 1,
                isShow: true,
                isTotal: false,
                isSort: true,
            }, */
            {
                name: "收件人姓名",
                field: "consigneeName",
                width: "120",
                sort: 1,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "国家",
                field: "chineseName",
                width: "120",
                sort: 1,
                isShow: true,
                isTotal: false,
                isSort: true,
            },

            {
                name: "收货详细地址",
                field: "addressee",
                width: "120",
                sort: 1,
                isShow: true,
                isTotal: false,
                isSort: true,
            },

            /* {
                name: "品名",
                field: "",
                width: "80",
                sort: 11,
                isShow: true,
                isTotal: false,
                isSort: true,
            }, */
            {
                name: "件数",
                field: "count",
                width: "80",
                sort: 12,
                isShow: true,
                isTotal: true,
                isSort: true,
            },
            {
                name: "实际重量(KG)",
                field: "weight",
                width: "120",
                sort: 13,
                isShow: true,
                isTotal: true,
                isSort: true,
            },
            {
                name: "实际体积(m³)",
                field: "volume",
                width: "120",
                sort: 14,
                isShow: true,
                isTotal: true,
                isSort: true,
            },

            {
                name: "包裹备注",
                field: "comment",
                width: "180",
                sort: 15,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "打包时间",
                field: "packApplyTime",
                width: "180",
                sort: 22,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
                /* {
                    name: "打包人",
                    field: "",
                    width: "120",
                    sort: 1,
                    isShow: true,
                    isTotal: false,
                    isSort: true,
                }, */


            ],
            tableData: [],
            tableData1: [],
            totalArr: [], //需要合计的字段
            totalArr1: [], //需要合计的字段
            className: "", //导出需要
            fieldName: [], //下载字段名
            sortName: '模板名', //排序名
            sortName1: '模板名', //排序名
            pageGroupName: 'DeliveryConfirmation', //页面标识
            head: [], //下载表头字段
            fileList: [],
            uploadUrl: "", //上传路径

            fullscreen: false,
            TableStyle: true,

            inputt: '',
            huojiaList: [], //货架
            huoquList: [], //货区
            cangkuList: [], //仓库列表

            startTime: '',
            endTime: '',
            timeType: '',
            timeTypes: [
                {
                    value: '0',
                    label: '出库时间'
                },
            ],
        };
    },
    updated() {
        if (this.show_sortTableHeard) {
            // 排序表头
            let arr = this.tabelHeadeTitle;
            for (var i = 0; i < arr.length; i++) {
                arr[i].sort = i + 1;
            }
            this.tabelHeadeTitle = arr;
        }

    },
    created() {
        this.uploadUrl = this.common.uploadUrl;
        if (this.originalTabelHeadeTitle.length == 0) {
            this.originalTabelHeadeTitle = JSON.parse(
                JSON.stringify(this.tabelHeadeTitle)
            );
        }
        if (this.originalTabelHeadeTitle1.length == 0) {
            this.originalTabelHeadeTitle1 = JSON.parse(
                JSON.stringify(this.tabelHeadeTitle1)
            );
        }
        this.getGroupFindFieldSort(); //获取用户自定义表头
        this.getChannelAgentChooseList() //  获取渠道代理Id下拉
        this.getChannelTransportIdArr() //  获取渠道和运输方式下拉
        this.getStorageIdArr() //  获取仓库下拉

        let endtime = tools.getCurrentDate(); //今天的时间;
        let sarttime = tools.getFlexDate(-10); //10天前
        this.time_first = [sarttime, endtime]

        this.startTime = sarttime;
        this.endTime = endtime;
    },
    beforeDestroy() {

    },
    mounted() {
        this.getData();

    },

    watch: {},
    methods: {
        goAddYiChang() {
            this.$router.push({
                path: "/exceptionhandling/Newexception",
            });
        },
        //   ----- 基础事件 -----
        getData() { //  获取数据
            let param = {}
            if (this.activeName === 'second') {
                this.queryData.pageStart = this.pageStart
                this.fetTableHeight();
                this.queryData.pageTotal = this.pageTotal
                this.queryData.storepackStatus = 'dai_qian_shou:storepack_status'

                let endTime = '';
                let startTime = '';
                if (this.endTime) {
                    endTime = this.endTime + ' 23:59:59'
                }
                if (this.startTime) {
                    startTime = this.startTime + ' 00:00:00'
                }
                this.queryData.outstockStartTime = startTime
                this.queryData.outstockEndTime = endTime
                param = this.queryData
            } else {
                this.queryData.pageStart = this.pageNum
                this.queryData.pageTotal = this.size
                this.queryData.storepackStatus = 'wan_cheng_yi_fu:storepack_status'
                param = this.queryData
            }
            Api.outStockList(param).then((res) => {
                if (res.data.status === "success") {
                    this.fetTableHeight();
                    this.className = res.data.result.className || "";
                    if (this.activeName === 'second') {
                        this.tableData = res.data.result.data || [];
                        this.pageCount = res.data.result.pageCount || 0;
                    } else {
                        this.tableData = res.data.result.data || [];
                        this.total = res.data.result.pageCount || 0;
                    }

                }
            });
        },

        queryBtn_time(s, e) {
            this.startTime = s || ''
            this.endTime = e || ''
        },
        timestatus(type, s, e) {
            this.timeType = type || '0'
            this.startTime = s || ''
            this.endTime = e || ''
        },

        getChannelTransportIdArr() { //  获取渠道和运输方式下拉
            Api.channelAndTransportList().then(res => {
                if (res.data.status === 'success') {
                    let obj = {}
                    this.transportList = res.data.result.reduce((cur, next) => { //  去重
                        obj[next.channelTransportId] ? '' : obj[next.channelTransportId] = true && cur.push(next);
                        return cur
                    }, [])
                    this.channelAndTransportList = res.data.result || []
                } else {
                    this.$message.error(`${res.data.message}--渠道与运输方式下拉菜单`)
                }
            })
        },

        selectTId(val) { //  选择运输方式下拉框时筛选渠道数据
            let arr = []
            this.queryData.channelId = ''
            this.channelAndTransportList.forEach(item => {
                if (item.channelTransportId === val) {
                    arr.push(item)
                }
            })
            this.channelList = arr
            this.queryBtn_ok();
        },

        getChannelAgentChooseList() { //  获取渠道代理
            Api.channelAgentChooseList().then(res => {
                if (res.data.status === 'success') {
                    this.agentIdArr = res.data.result || []
                } else {
                    this.$message.error(`${res.data.message}--渠道代理`)
                }
            })
        },
        getStorageIdArr() {
            Api.getStorageList().then((res) => {
                if (res.data.status === "success") {
                    this.storageArr = res.data.result || []
                    console.log(res.data.result)
                } else {
                    console.log(`${res.data.message}--仓库下拉`)
                }
            });
        },
        queryBtn_ok() { //  查询按钮
            let nidlist = new Array
            nidlist = tools.getOrderNum(this.number1)
            this.queryData.storepackNumber = nidlist
            if (this.activeName === 'first') {
                this.pageNum = 1;
            } else {
                this.pageStart = 1;
            }
            this.getData()
        },

        resetFeild() { //  重置
            this.number1 = ""
            if (this.activeName === 'first') {
                this.queryData = {}
                this.time_first = []
            } else {
                this.queryData = {}
                this.startTime = '';
                this.endTime = '';
            }
        },

        handleSelectionChange(e) { //  表格选择事件
            this.singleData = e
        },
        //分页
        HSC_CK(val) {
            this.size = val;
            this.getData();
        },
        HCC_CK(val) {
            this.pageNum = val;
            this.getData();
        },
        HSC_JL(val) {
            this.pageTotal = val;
            this.getData();
        },
        HCC_JL(val) {
            this.pageStart = val;
            this.getData();
        },
        // 暂扣
        withhold() {
            let ids = [];
            if (this.singleData.length < 1) {
                return false
            }
            this.$confirm(`确定把所选的${this.singleData.length}个单据标记为暂扣?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                ids = this.singleData.map(items => {
                    return items.billPackId
                })

                Api.withhold({
                    billPackIds: ids
                }).then(res => {
                    this.$message.success(res.data.message || '操作成功')
                })

            })

        },
        // 取消暂扣
        cancelWithhold() {
            let ids = [];
            if (this.singleData.length < 1) {
                return false
            }
            this.$confirm(`确定把所选的${this.singleData.length}个单据取消暂扣?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                ids = this.singleData.map(items => {
                    return items.billPackId
                })

                Api.cancelWithhold({
                    billPackIds: ids
                }).then(res => {
                    this.$message.success(res.data.message || '操作成功')
                })

            })



        },


        ExWarehouse() { //  出库确认&取消出库
            let _this = this
            let btnType = _this.Tools.isDaiGou() || false;
            if (this.singleData.length > 0) {
                let param = {
                    storepackId: []
                }
                let ids = [];
                this.singleData.forEach(item => {
                    param.storepackId.push(item.storepackId)
                    ids.push(item.billPackId)
                })
                if (this.activeName === 'first') {
                    let sign = tools.getSign(param);
                    param.sign = sign
                    this.$confirm(`确认出库数量${this.singleData.length}个`, '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {

                        let paramA = {
                            billPackId: ids || [],
                            comment: "",
                        };
                        paramA.sign = tools.getSign(paramA);
                        Api.packPackageOutStockV2(paramA).then((res) => {
                            this.$message.success(res.data.message || "出库成功");
                            _this.getData();
                        });

                        // if (btnType) {
                        //     // 八米购
                        //     console.log('八米购')
                        //     Api.bmg_outStock(param).then(res => {
                        //         this.$message.success('出库成功')
                        //         _this.getData()
                        //     })
                        // } else {
                        //     // 原来的
                        //     Api.outStock(param).then((res) => {
                        //         if (res.data.status === "success") {
                        //             this.$message.success('出库成功')
                        //             _this.getData()
                        //         }

                        //     })
                        // }

                    });
                } else if (this.activeName === 'second') {
                    this.$confirm(`取消出库数量${this.singleData.length}个`, '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        if (btnType) {
                            Api.bmg_cancelOutStock(param).then((res) => {
                                if (res.data.status === "success") {
                                    this.$message.success('取消出库成功')
                                    _this.getData()
                                }
                            })
                        } else {
                            Api.cancelOutStock(param).then((res) => {
                                if (res.data.status === "success") {
                                    this.$message.success('取消出库成功')
                                    _this.getData()
                                }
                            })
                        }


                    });
                }
            } else {
                this.$message.warning('请至少勾选一条数据');
            }
        },

        handleCommandTabs(m) {
            if (m == "a") {
                this.myModel()
            } else if (m == 'b') {
                this.exportBtn()
            } else if (m == 'c') {
                this.exportTemplateDownload()
            } else if (m == 'd') {
                this.upLoadBtn()
            } else {
                this.screen()
            }

        },


        more() { //  输入框绑定动态class
            this.isActive = !this.isActive
            this.judge = !this.judge
            this.fetTableHeight();
            if (this.judge) {
                this.MoreConditions = '收起条件'
            } else {
                this.MoreConditions = '更多条件'
            }
        },


        //导出操作
        handleCommand_dc(command) {
            if (command == 'dc') {
                // 导出
                this.exportBtn()
            } else if (command == 'dcmb') {
                // 下载模板
                this.exportTemplateDownload()
            } else if (command == 'scmb') {
                // 上传模板
                this.upLoadBtn()
            }
        },


        // 上传导出模板
        uploadSectionFile(param) {
            //console.log(param);
            const file = param.file,
                fileName = file.name;

            // 根据后台需求数据格式
            const form = new FormData();
            // 文件对象
            form.append("file", file);
            // 本例子主要要在请求时添加特定属性，所以要用自己方法覆盖默认的action
            form.append("fileName", fileName);
            let signArr = [{
                key: "fileName",
                val: fileName,
            },];
            let sign = tools.getMD5Sign(signArr);
            form.append("sign", sign);
            // 项目封装的请求方法，下面做简单介绍
            Api.uploadFile(form)
                .then((res) => {
                    if (res.data.status == "success") {
                        let fileName = res.data.result.fileName;

                        let signArr = [{
                            key: "groupName",
                            val: this.pageGroupName,
                        },
                        {
                            key: "templateName",
                            val: this.sortName,
                        },
                        {
                            key: "fileName",
                            val: fileName,
                        },
                        ];
                        let sign_ = tools.getMD5Sign(signArr);
                        Api.saveExportTemplate({
                            groupName: this.pageGroupName,
                            templateName: this.sortName,
                            fileName: fileName,
                            sign: sign_,
                        }).then((res) => {
                            this.$message.success(res.data.message || "更新成功");
                            this.show_upload = false;
                        });
                    }
                })
                .catch((err) => { });
        },

        // 下载用户导出模板
        exportTemplateDownload() {
            var fieldName = this.fieldName;
            var head = this.head;
            if (head.length == 0) {
                let fieldNames = [];
                let heads = [];
                if (this.pageGroupName == 'DeliveryConfirmation') {
                    for (var i = 0; i < this.myTableHeard.length; i++) {
                        if (this.myTableHeard[i].isShow) {
                            fieldName.push(this.myTableHeard[i].field);
                            head.push(this.myTableHeard[i].name);
                        }
                    }
                }
                if (this.pageGroupName == 'DeliveryConfirmation3') {
                    for (var i = 0; i < this.myTableHeard1.length; i++) {
                        if (this.myTableHeard1[i].isShow) {
                            fieldName.push(this.myTableHeard1[i].field);
                            head.push(this.myTableHeard1[i].name);
                        }
                    }
                }
                fieldName = fieldNames;
                head = heads;
            }
            //加密数组格式
            let signArr = [{
                key: "fieldName",
                val: fieldName,
            },
            {
                key: "head",
                val: head,
            },
            ];
            let sign = tools.getMD5Sign(signArr);
            let param = {
                fieldName: fieldName,
                head: head,
                sign: sign,
            };
            Api.exportTemplateDownload(param).then((res) => {
                let downloadurl = res.data.result.fileUrl || "";
                //下载(这里其实是直接访问)
                var a = document.createElement("a");
                a.setAttribute("href", downloadurl);
                a.setAttribute("target", "_blank");
                a.click();
            });
        },
        // 真正导出
        templateDataExport(customizedExportId) {
            let that = this;

            let datas;
            datas = that.tableData;
            // if (this.pageGroupName == 'DeliveryConfirmation') {

            // } else {
            //     datas = that.tableData1;
            // }
            let parm = {
                customizedExportId: customizedExportId, //模板id
                className: that.className,
                data: datas,
            };
            let signarr = tools.getSignArr(parm);
            let signs_e = tools.getMD5Sign(signarr);
            Api.templateDataExport({
                customizedExportId: customizedExportId,
                className: that.className,
                data: JSON.stringify(datas),
                sign: signs_e,
            }).then((res) => {
                //下载(这里其实是直接访问)
                let downloadurl = res.data.result.fileUrl;
                var a = document.createElement("a");
                a.setAttribute("href", downloadurl);
                a.setAttribute("target", "_blank");
                a.click();

            });
        },
        // 上传按钮
        upLoadBtn() {
            this.show_upload = true;
        },
        // 点击导出按钮
        exportBtn() {
            let that = this;
            let fieldName = [];
            let head = [];
            if (this.pageGroupName == 'DeliveryConfirmation') {
                for (var i = 0; i < this.myTableHeard.length; i++) {
                    if (this.myTableHeard[i].isShow) {
                        fieldName.push(this.myTableHeard[i].field);
                        head.push(this.myTableHeard[i].name);
                    }
                }
            }
            if (this.pageGroupName == 'DeliveryConfirmation3') {
                for (var i = 0; i < this.myTableHeard1.length; i++) {
                    if (this.myTableHeard1[i].isShow) {
                        fieldName.push(this.myTableHeard1[i].field);
                        head.push(this.myTableHeard1[i].name);
                    }
                }
            }
            this.fieldName = fieldName;
            this.head = head;

            // 1,先获取用户导出模板
            Api.getUserExportTemplate({
                groupName: this.pageGroupName,
            }).then((res) => {
                if (res.data.result && res.data.result[0].customizedExportId) {
                    // 有自定义模板

                    let customizedExportId = res.data.result[0].customizedExportId;
                    that.templateDataExport(customizedExportId);


                } else {
                    if (this.pageGroupName == 'DeliveryConfirmation') {
                        tools.dynamicExport(
                            this.myTableHeard,
                            this.className,
                            this.tableData,
                            "出库确认列表"
                        );
                    } else {
                        tools.dynamicExport(
                            this.myTableHeard1,
                            this.className,
                            this.tableData,
                            // this.tableData1,
                            "出库记录列表"
                        );
                    }

                }
            });
        },


        resetHeight() { //   重置table高度
            return new Promise((resolve, reject) => {
                this.tableHeigth = 0;
                resolve();
            });
        },

        fetTableHeight() { //  设置table高度
            this.resetHeight().then((res) => {
                this.tableHeigth = this.getHeight();
                this.$nextTick(() => {
                    this.$refs.mytable.doLayout();
                });
            });
        },

        getHeight() { //  获取表格高度
            let windonHeight = document.body.clientHeight
            let queryHeigth
            let btnListHeight = this.$refs.btnList.offsetHeight + 16; // 按钮列表  16==上下的margin
            if (this.judge) {
                queryHeigth = this.$refs.queryCriteria.offsetHeight; //查询框
                if (this.activeName === "second") {
                    queryHeigth = this.$refs.queryCriteria1.offsetHeight; //查询框
                }
            } else {
                queryHeigth = 50
            }
            let pagsHeigth = 60; //分页组件
            var otherHeight = queryHeigth + btnListHeight + pagsHeigth + 100;
            return Math.floor(windonHeight - otherHeight) || "auto"; //
        },


        handleClick() { //  顶部标签页切换
            this.number1 = ""
            this.queryData = {}
            if (this.activeName === 'second') {
                this.pageGroupName = 'DeliveryConfirmation3'
                this.SignInRecord()
                this.getData()
            } else {
                this.pageGroupName = 'DeliveryConfirmation'
                this.getGroupFindFieldSort()
                this.getData()
            }
            this.singleData = []
        },

        SignInRecord() { //  签收记录表头获取
            let that = this;
            //console.log(this.pageGroupName)
            Api.getGroupFindFieldSort({
                groupName: this.pageGroupName, //当前页面表格的标记(自定义字符串)
            }).then((res) => {
                let arr = res.data.result || [];
                if (arr.length > 0) {
                    if (arr[0].sortObject) {
                        that.myTableHeard1 = JSON.parse(arr[0].sortObject);
                        this.sortName1 = arr[0].sortName;
                    }
                } else {
                    that.myTableHeard1 = that.tabelHeadeTitle1;

                }
                let totalArr = [];
                //提取需要合计的字段
                for (var i = 0; i < that.myTableHeard1.length; i++) {
                    if (that.myTableHeard1[i].isTotal) {
                        totalArr.push(that.myTableHeard1[i].field);
                    }
                }
                that.totalArr1 = totalArr;
            });

            that.$nextTick(() => {
                that.$refs.mytable.doLayout();
            });
        },

        getGroupFindFieldSort() { //  获取自定义的排序,没有就用默认的
            let that = this;
            Api.getGroupFindFieldSort({
                groupName: this.pageGroupName, //当前页面表格的标记(自定义字符串)
            }).then((res) => {
                let arr = res.data.result || [];
                if (arr.length > 0) {
                    if (arr[0].sortObject) {
                        that.myTableHeard = JSON.parse(arr[0].sortObject);
                        this.sortName = arr[0].sortName;
                    }
                } else {
                    that.myTableHeard = that.tabelHeadeTitle;

                }
                let totalArr = [];
                //提取需要合计的字段
                for (var i = 0; i < that.myTableHeard.length; i++) {
                    if (that.myTableHeard[i].isTotal) {
                        totalArr.push(that.myTableHeard[i].field);
                    }
                }
                that.totalArr = totalArr;
            });

            that.$nextTick(() => {
                that.$refs.mytable.doLayout();
            });
        },
        // 设置模板按钮
        myModel() {
            let that = this;
            that.show_sortTableHeard = true;
            that.tabelHeadeTitle = JSON.parse(JSON.stringify(that.myTableHeard));
            let selectLength = 0;
            for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
                if (that.tabelHeadeTitle[i].isShow) {
                    selectLength++;
                }
            }
            that.selectLength = selectLength;
            if (selectLength === that.tabelHeadeTitle.length) {
                that.allCheck = true;
            } else {
                that.allCheck = false;
            }
        },
        //设置模板按钮(签收记录)
        myModel1() {
            let that = this;
            that.show_sortTableHeard1 = true;
            that.tabelHeadeTitle1 = JSON.parse(JSON.stringify(that.myTableHeard1));
            let selectLength = 0;
            for (var i = 0; i < that.tabelHeadeTitle1.length; i++) {
                if (that.tabelHeadeTitle1[i].isShow) {
                    selectLength++;
                }
            }
            that.selectLength1 = selectLength;
            if (selectLength === that.tabelHeadeTitle1.length) {
                that.allCheck1 = true;
            } else {
                that.allCheck1 = false;
            }
        },


        // 排序==>取消按钮
        cancelSortHeard() {
            this.show_sortTableHeard = false;
            this.$nextTick(() => {
                this.$refs.mytable.doLayout();
            });
        },
        // 排序==>确认按钮
        confirmOk(e) {
            this.tabelHeadeTitle = e;
            this.myTableHeard = this.tabelHeadeTitle;
            let sortObject = this.myTableHeard;
            let signs = [{
                key: "sortName",
                val: this.sortName, //模板名称
            },
            {
                key: "groupName",
                val: this.pageGroupName, //页面标识
            },
            {
                key: "sortObject",
                val: JSON.stringify(sortObject),
            },
            ];

            let sign = tools.getMD5Sign(signs);

            let param = {
                // apiPath: apiList.api_packageList, //综合查询接口路径
                groupName: this.pageGroupName, //当前页面表格的标记(自定义字符串)
                sortName: this.sortName, //综合查询接口路径
                sortObject: JSON.stringify(sortObject),
                sign: sign,
            };
            Api.pushFindFieldSort(param).then((res) => {
                this.$message.success(res.data.message || "操作成功");
                this.getGroupFindFieldSort();
            });
            this.show_sortTableHeard = false; //关闭弹窗
            this.$nextTick(() => {
                this.$refs.mytable.doLayout();
            });
            this.show_sortTableHeard = false;
        },
        // 点击某一项
        checktab(idx, e) {
            let that = this;
            that.tabelHeadeTitle[idx].isShow = e;
            let selectLength = 0;
            for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
                if (that.tabelHeadeTitle[i].isShow) {
                    selectLength++;
                }
            }
            that.selectLength = selectLength;
            if (selectLength == that.tabelHeadeTitle.length) {
                that.allCheck = true;
            } else {
                that.allCheck = false;
            }
        },
        //全选
        checkAll(e) {
            let that = this;
            this.allCheck = e;
            for (var j = 0; j < this.tabelHeadeTitle.length; j++) {
                this.tabelHeadeTitle[j].isShow = e;
            }
            let selectLength = 0;
            for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
                if (that.tabelHeadeTitle[i].isShow) {
                    selectLength++;
                }
            }
            that.selectLength = selectLength;
        },
        //关闭弹窗
        closeDias(e) {
            this.show_sortTableHeard = e;
        },
        // 合计
        getSummaries(param) {
            let that = this;
            const {
                columns,
                data
            } = param;
            const sums = [];
            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = "合计";
                    return;
                }
                const values = data.map((item) => Number(item[column.property]));
                for (var i = 0; i < that.totalArr.length; i++) {
                    if (column.property == that.totalArr[i]) {
                        if (!values.every((value) => isNaN(value))) {
                            sums[index] = values.reduce((prev, curr) => {
                                const value = Number(curr);
                                if (!isNaN(value)) {
                                    return prev + curr;
                                } else {
                                    return prev;
                                }
                            }, 0);
                            sums[index] += "";
                        } else {
                            sums[index] = "---";
                        }
                    }
                }
            });
            this.$nextTick(() => {
                this.$refs.mytable.doLayout();
            });
            return sums;
        },

        // 排序==>取消按钮(签收记录)
        cancelSortHeard1() {
            this.show_sortTableHeard1 = false;
            this.$nextTick(() => {
                this.$refs.mytable.doLayout();
            });
        },
        // 排序==>确认按钮(签收记录)
        confirmOk1(e) {
            this.tabelHeadeTitle1 = e;
            this.myTableHeard1 = this.tabelHeadeTitle1;
            let sortObject = this.myTableHeard1;
            let signs = [{
                key: "sortName",
                val: this.sortName1, //模板名称
            },
            {
                key: "groupName",
                val: this.pageGroupName, //页面标识
            },
            {
                key: "sortObject",
                val: JSON.stringify(sortObject),
            },
            ];

            let sign = tools.getMD5Sign(signs);

            let param = {
                // apiPath: apiList.api_packageList, //综合查询接口路径
                groupName: this.pageGroupName, //当前页面表格的标记(自定义字符串)
                sortName: this.sortName1, //综合查询接口路径
                sortObject: JSON.stringify(sortObject),
                sign: sign,
            };
            Api.pushFindFieldSort(param).then((res) => {
                this.$message.success(res.data.message || "操作成功");
                this.getGroupFindFieldSort();
            });
            this.show_sortTableHeard1 = false; //关闭弹窗
            this.$nextTick(() => {
                this.$refs.mytable1.doLayout();
            });
            this.show_sortTableHeard1 = false;
        },
        // 点击某一项
        checktab1(idx, e) {
            let that = this;
            that.tabelHeadeTitle1[idx].isShow = e;
            let selectLength = 0;
            for (var i = 0; i < that.tabelHeadeTitle1.length; i++) {
                if (that.tabelHeadeTitle1[i].isShow) {
                    selectLength++;
                }
            }
            that.selectLength1 = selectLength;
            if (selectLength == that.tabelHeadeTitle1.length) {
                that.allCheck1 = true;
            } else {
                that.allCheck1 = false;
            }
        },
        //全选
        checkAll1(e) {
            let that = this;
            this.allCheck1 = e;
            for (var j = 0; j < this.tabelHeadeTitle1.length; j++) {
                this.tabelHeadeTitle1[j].isShow = e;
            }
            let selectLength = 0;
            for (var i = 0; i < that.tabelHeadeTitle1.length; i++) {
                if (that.tabelHeadeTitle1[i].isShow) {
                    selectLength++;
                }
            }
            that.selectLength1 = selectLength;
        },
        // 合计
        getSummaries1(param) {
            let that = this;
            const {
                columns,
                data
            } = param;
            const sums = [];
            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = "合计";
                    return;
                }
                const values = data.map((item) => Number(item[column.property]));
                for (var i = 0; i < that.totalArr1.length; i++) {
                    if (column.property == that.totalArr1[i]) {
                        if (!values.every((value) => isNaN(value))) {
                            sums[index] = values.reduce((prev, curr) => {
                                const value = Number(curr);
                                if (!isNaN(value)) {
                                    return tools.mathToFixed(tools.add(prev, curr), 2)
                                } else {
                                    return prev;
                                }
                            }, 0);
                            sums[index] += "";
                        } else {
                            sums[index] = "---";
                        }
                    }
                }
            });
            this.$nextTick(() => {
                this.$refs.mytable1.doLayout();
            });
            return sums;
        },
        //关闭弹窗1
        closeDias1(e) {
            this.show_sortTableHeard1 = e;
        },

    }
}